import React, { useEffect, useState } from "react";
import { startOfMonth, isSameMonth, getUnixTime } from "date-fns";
import { format, getDuration } from "../../utils/dateUtils";
import { uniqBy, get, isEmpty } from "lodash";
import LoadingSpinner from "../loadingSpinner";

const monthDateFormat = "MMMM";
const dayDateFormat = "d";

const PriceOptions = ({ cmsProduct, erpProductOptions, innerRef }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [previousYearHasData, setPreviousYearHasData] = useState(false);
  const [nextYearHasData, setnextYearHasData] = useState(false);
  const [sortedOptions, setSortedOptions] = useState();

  let [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (erpProductOptions && !isEmpty(erpProductOptions)) {
      setIsLoading(true);
      const dataSelectedYear = erpProductOptions.filter(
        option => new Date(option.fromDate).getFullYear() === selectedYear
      );
      const dataNextYear = erpProductOptions.filter(
        option => new Date(option.fromDate).getFullYear() === selectedYear + 1
      );
      const dataYearAfterNext = erpProductOptions.filter(
        option => new Date(option.fromDate).getFullYear() === selectedYear + 2
      );
      const data = { selectedYear: dataSelectedYear, nextYear: dataNextYear, yearAfterNext: dataYearAfterNext };

      const getOptions = data => {
        let localAllOptions = [];
        Object.keys(data).map(year => {
          let optionsForYear = [];

          // Determine all months
          if (data[year] && data[year].length > 0) {
            let months = [];
            for (const option of data[year]) {
              months.push(startOfMonth(new Date(option.fromDate)));
            }
            months = uniqBy(months, month => getUnixTime(month));

            // Sort options by month
            for (const month of months) {
              const optionsForMonth = data[year].filter(option => {
                return isSameMonth(new Date(option.fromDate), month);
              });

              const options = [];
              optionsForMonth.forEach(x => {
                const existingOption = options.find(y => y.id === x.allotment.allotmentIds[0]);
                if (existingOption) {
                  existingOption.accommodations.push({
                    code: x.accommodationCode,
                    name: x.accommodationName,
                    price: x.averagePricePerPerson,
                  });
                } else {
                  options.push({
                    id: x.allotment.allotmentIds[0],
                    fromDate: x.fromDate,
                    duration: getDuration(x.fromDate, x.toDate),
                    accommodations: [
                      {
                        code: x.accommodationCode,
                        name: x.accommodationName,
                        price: x.averagePricePerPerson,
                      },
                    ],
                  });
                }
              });

              options.forEach(x => {
                x.accommodations.sort((a, b) => a.code - b.code);
              });

              //Group per month
              optionsForYear.push({
                month: month,
                options: options,
              });
            }
            // Group per year
            localAllOptions.push({
              year: optionsForYear[0].month.getFullYear(),
              months: optionsForYear,
            });
          }
          return localAllOptions;
        });

        setAllOptions(localAllOptions);
      };

      getOptions(data);
    }
  }, [erpProductOptions]);

  useEffect(() => {
    let optionsForSelectedYear = allOptions.find(options => options.year === selectedYear);

    if ((!optionsForSelectedYear || optionsForSelectedYear.length <= 0) && allOptions.length > 0) {
      setSelectedYear(allOptions[0].year);
    }

    if (optionsForSelectedYear) {
      setSortedOptions(optionsForSelectedYear.months);
      checkYears(selectedYear);
    }
    setIsLoading(false);
  }, [allOptions, selectedYear]);

  if (!cmsProduct) {
    return <></>;
  }

  const handleOnLower = () => {
    setSelectedYear(selectedYear - 1);
  };

  const handleOnHigher = () => {
    setSelectedYear(selectedYear + 1);
  };

  const checkYears = yearToCheck => {
    setPreviousYearHasData(allOptions.find(options => options.year === yearToCheck - 1) !== undefined);
    setnextYearHasData(allOptions.find(options => options.year === yearToCheck + 1) !== undefined);
  };

  const getPath = option => {
    if (!cmsProduct?.content?.general?.path || !option?.fromDate || !option.duration) {
      return "/";
    }

    return `/products/${cmsProduct.content.general.path}?from=${getUnixTime(new Date(option.fromDate))}&duration=${
      option.duration
    }`;
  };

  return (
    <>
      {isLoading && <LoadingSpinner size="fa-3x" />}
      {allOptions.length > 0 && !isLoading && (
        <section className="product-departures" ref={innerRef}>
          <div className="product-departures__title">
            <h2>Vertrekdata</h2>
          </div>
          <div className="product-departures__content">
            <div className="product-departures__year">
              <button
                className={previousYearHasData ? "button" : "button button__disabled"}
                disabled={!previousYearHasData || isLoading}
                onClick={handleOnLower}
              >
                <span className="button__icon">
                  <i className="fas fa-arrow-left"></i>
                </span>
              </button>
              <h4>{selectedYear}</h4>
              <button
                className={nextYearHasData ? "button" : "button button__disabled"}
                disabled={!nextYearHasData || isLoading}
                onClick={handleOnHigher}
              >
                <span className="button__icon">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </button>
            </div>
            <div className="table-responsive">
              <table>
                <tbody>
                  <tr>
                    <th>MAAND</th>
                    <th>DAG</th>
                    {get(sortedOptions, "[0].options[0].accommodations", []).map((acco, i) => (
                      <th key={i}>{acco.name}</th>
                    ))}
                  </tr>
                  {sortedOptions &&
                    sortedOptions.map((month, monthIndex) =>
                      month.options.map((option, optionIndex) => (
                        <tr key={optionIndex}>
                          <td>{format(month.month, monthDateFormat)}</td>
                          <td>
                            <a href={getPath(option)} className="link" key={optionIndex}>
                              {format(new Date(option.fromDate), dayDateFormat)}
                            </a>
                          </td>

                          {option.accommodations.map((acco, i) => (
                            <td key={i}>€ {acco.price}</td>
                          ))}
                        </tr>
                      ))
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PriceOptions;
