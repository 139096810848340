import React, { useContext, useState } from "react";
import { getStars } from "../../utils/mappingUtils";
import GlobalContext from "../../contexts/global-provider";

const ScheduleLeg = ({ scheduleEntrie, index }) => {
  const { theme } = useContext(GlobalContext);
  const [showDayLinkDetails, setShowDayLinkDetails] = useState(index === 0);

  const HandleShowDayLinkDetailClick = () => {
    setShowDayLinkDetails(!showDayLinkDetails);
  }

  if (!scheduleEntrie.content?.general && !scheduleEntrie.content?.general?.dayDescription) {
    return <></>;
  }

  const dayLink = scheduleEntrie.content.general;
  const dayDefinition = dayLink.dayDescription.content;
  const overruleInformation = dayLink.overruleInformation;

  return (
    <div
      key={index}
      className={`product-schedule__leg collapse ${showDayLinkDetails ? "collapse--show" : ""}`}
      onClick={() => HandleShowDayLinkDetailClick()}
    >
      <div className="product-schedule__leg-header">
        <h3>
          {dayLink["fromTill"]} <img src={`/${theme}/svg/bullet.svg`} alt="bullet" /> {dayDefinition.general.title}
        </h3>
      </div>
      <div className="product-schedule__leg-body">
        {overruleInformation ? (
          <div dangerouslySetInnerHTML={{ __html: overruleInformation }} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: dayDefinition.general.description }} />
        )}

        <div className="extra info" dangerouslySetInnerHTML={{ __html: dayLink.extraInformation }} />

        {(!scheduleEntrie.hotels || !scheduleEntrie.hotels.length) && <img src={`/${theme}/svg/wave-schedule.svg`} alt="wave" />}

        {scheduleEntrie.hotels && scheduleEntrie.hotels.length > 0 && (
          <div>
            {scheduleEntrie.hotels.map((hotel, i) => (
              <div key={hotel.itemId} className="hotel-item">
                <div className="hotel-item__media">
                  <img
                    src={hotel.content.general.image.url}
                    alt={hotel.content.general.title}
                    className="hotel-item__media-content"
                  />
                </div>
                <div className="hotel-item__content">
                  <p>{hotel.content.general.title}</p>
                  <div className="hotel-item__rating">
                    <sup>
                      {getStars(hotel.content.general.stars).map((star, i) => (
                        <i key={i} className={`fa ${star.isHalf ? "fa-star-half" : "fa-star"}`} />
                      ))}
                    </sup>
                  </div>
                  <div className="hotel-item__cta">
                    <a href="#hotelInfo">Lees onderaan deze pagina meer</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleLeg;
