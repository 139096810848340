export const getStars = stars => {
  // Fast excape
  if (!stars) {
    return [];
  }

  let temporaryStars = stars;
  let parsedStars = [];

  while (temporaryStars > 0) {
    const decimal = temporaryStars % 1;
    parsedStars.push({ isHalf: Boolean(decimal) });

    if (Boolean(decimal)) {
      temporaryStars -= decimal;
    } else {
      temporaryStars -= 1;
    }
  }

  return parsedStars.sort((star1, star2) => (star1.isHalf === star2.isHalf ? 0 : star1.isHalf ? 1 : -1));
};