import { max, min } from "date-fns";

import ImageGallery from "react-image-gallery";
import React, { useState } from "react";
import { format, getDuration } from "../../utils/dateUtils";
import LoadingSpinner from "../loadingSpinner";
import { useBodyClass } from "../../utils/bodyClassUtils";
import { Link, navigate } from "gatsby";
import { getStars } from "../../utils/mappingUtils";
import { addImage } from "../../utils/imageUtils";
import { groupBy, isEmpty } from "lodash";

const shortDateFormat = "dd MMM";
const longDateFormat = "MMMM yyyy";

export default ({
  theme,
  cmsProduct,
  erpProductOptions,
  onOfferClick,
  onDepartureDateClick,
  selectedOption,
  bookingDisclaimer,
  shippingCompany,
  isLoading,
}) => {
  const [isImageGalleryActive, setIsImageGalleryActive] = useState(false);
  useBodyClass(isImageGalleryActive, "has-overlay");

  // Fast return when there's no cmsProduct
  if (!cmsProduct) {
    return <></>;
  }

  // Actions

  const handleOfferClick = e => {
    e.preventDefault();

    if (onOfferClick) {
      onOfferClick();
    }
  };

  const handleOnDepartureDateClick = e => {
    e.preventDefault();

    if (onDepartureDateClick) {
      onDepartureDateClick();
    }
  };

  const handleOnShippingCompanyClick = e => {
    e.preventDefault();

    navigate(`/rederijen/${shippingCompany.content.general.detailPath}`);
  };

  const handleScreenchange = () => {
    setIsImageGalleryActive(!isImageGalleryActive);
  };

  // Data
  let option;
  if (selectedOption) {
    option = selectedOption;
  } else {
    if (erpProductOptions) {
      option = erpProductOptions.sort((a, b) => new Date(a.fromDate) - new Date(b.fromDate))[0];
    }
  }

  const fromDate = option ? new Date(option.fromDate) : undefined;
  const duration = getDuration(option?.fromDate, option?.toDate);
  const averagePricePerPerson = option?.averagePricePerPerson;

  const cruiseType = cmsProduct.content.general.cruiseType;
  const ship = cmsProduct.content.general.ship;
  const stars = getStars(ship?.content.general.stars);

  let images = [];
  if (cmsProduct.content.images.images) {
    cmsProduct.content.images.images.forEach(image => {
      addImage(images, image.url);
    });
  }

  return (
    <section className="product-detail">
      <div className="product-detail__content">
        <div className="product-detail__image">
          <ImageGallery
            items={images}
            useBrowserFullscreen={false}
            onScreenChange={handleScreenchange}
            showPlayButton={false}
          />
        </div>
        <div className="product-detail__info">
          <div className="product-detail__topics">
            {Boolean(cruiseType && cruiseType.content.general) && (
              <div className="product-detail__topic">
                <img src={`/${theme}/svg/topic-1.svg`} alt="topic" />
                {cruiseType.content.general.title}
              </div>
            )}
            {Boolean(ship) && (
              <div className="product-detail__topic">
                <img src={`/${theme}/svg/topic-4.svg`} alt="topic" />
                {ship.content.general.name}
              </div>
            )}
            {Boolean(shippingCompany && shippingCompany.content?.general?.logo?.url) && (
              <div
                className="product-detail__topic product-detail__topic--clickable product-detail__topic--large"
                onClick={handleOnShippingCompanyClick}
              >
                <img
                  src={shippingCompany.content.general.logo.url}
                  title={shippingCompany.content.general.logo.title}
                  alt={shippingCompany.content.general.logo.altText}
                />
              </div>
            )}
            {isLoading ? (
              <div className="product-detail__topic">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {!isEmpty(erpProductOptions) && (
                  <>
                    <div
                      className="product-detail__topic product-detail__topic--clickable"
                      onClick={handleOnDepartureDateClick}
                    >
                      <img src={`/${theme}/svg/topic-2.svg`} alt="topic" />
                      {erpProductOptions && Object.keys(groupBy(erpProductOptions, "fromDate")).length > 1
                        ? "Meerdere afreisdata"
                        : format(fromDate, shortDateFormat)}
                    </div>
                    <div className="product-detail__topic">
                      <span className="product-detail__topic--icon">{Math.floor(parseFloat(duration))}</span>
                      <span>Nachten</span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <h1 className="product-detail__title">{cmsProduct.content.general.title}</h1>
          <div className="product-detail__subtitle">
            {cmsProduct.content.information.subtitle}
            <sup className="product-detail__rating">
              {stars.map((star, i) => (
                <i key={i} className={`fa ${star.isHalf ? "fa-star-half" : "fa-star"}`} />
              ))}
            </sup>
          </div>
          <div className="product-detail__slot">{getSlot(erpProductOptions)}</div>
          <div className="product-detail__inclusive">
            <p className="product-detail__inclusive-title">Inclusief</p>
            <div
              className="product-detail__inclusive-items"
              dangerouslySetInnerHTML={{ __html: cmsProduct.content.information.inclusive }}
            />
          </div>
          {Boolean(option) && (
            <>
              <div className="product-detail__footer">
                <div className="product-detail__price">
                  <label className="product-detail__price-label">Vanaf</label>
                  <div className="product-detail__price-line">
                    <span className="product-detail__price-valuta">&euro;</span>
                    <span className="product-detail__price-cost">{averagePricePerPerson}</span>
                    <span className="product-detail__price-quantity">p.p.</span>
                  </div>
                </div>
                <div className="product-detail__actions">
                  {cmsProduct.content.general.externalBookingUrl && (
                    <div className="product-detail__actions-cta-booking">
                      <Link to={cmsProduct.content.general.externalBookingUrl} target="_blank">
                        Boek nu
                      </Link>
                    </div>
                  )}
                  <div className="product-detail__actions-cta" onClick={handleOfferClick}>
                    <a href="/">Offerte aanvragen</a>
                  </div>
                </div>
              </div>
              {cmsProduct.content.general.externalBookingUrl && bookingDisclaimer && (
                <div className="product-detail__disclaimer">{bookingDisclaimer}</div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};
const getLowestPrice = accommodations => {
  if (accommodations) {
    return accommodations.reduce((min, p) => (p.price < min ? p.price : min), accommodations[0].price);
  }
};

const getSlot = erpProductOptions => {
  if (!erpProductOptions) {
    return "";
  }

  try {
    const possibleFromDates = erpProductOptions.map(option => new Date(option.from));

    // There's only 1 date
    if (possibleFromDates.length === 1) {
      return `Afreizen mogelijk in ${format(possibleFromDates[0], longDateFormat)}`;
    }

    // There are multiple dates
    const minPossibleFromDate = min(possibleFromDates);
    const maxPossibleFromDate = max(possibleFromDates);

    return `Afreizen mogelijk van ${format(minPossibleFromDate, longDateFormat)} tot ${format(
      maxPossibleFromDate,
      longDateFormat
    )}`;
  } catch (e) {
    return "";
  }
};
