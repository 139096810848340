import { Link } from "gatsby";
import React from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";

export default ({ ship }) => (
  <section className="product-ship-card ">
    <div className="product-ship-card__header">
      <Link to={`/${ship.content.general.detailPath}`} className="link link--plain">
        <figure className="product-ship-card__figure">
          {Boolean(ship.content.general.image) && (
            <img src={ship.content.general.image.url} alt={ship.content.general.name} />
          )}
        </figure>
      </Link>
    </div>

    <div className="product-ship-card__body">
      <h3 className="product-ship-card__title">
        <Link to={`/${ship.content.general.detailPath}`} className="link link--plain">
          {ship.content.general.passengers} passagiers - Bouwjaar {ship.content.general.yearManufactured}
        </Link>
      </h3>
      <div
        className="product-ship-card__description"
        dangerouslySetInnerHTML={{ __html: ship.content.general.description }}
      />
      <div className="product-detail__actions">
        <div className="product-detail__actions-cta">
          <Link to={`/${ship.content.general.detailPath}`} className="link">
            Meer info
          </Link>
        </div>
      </div>
    </div>
  </section>
);
