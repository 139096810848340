import { filter, get } from "lodash";

import React from "react";
import ScheduleLeg from "./schedule-leg";
import GoogleMap from "../map/map";

const Schedule = ({ product, scheduleEntries }) => {
  const schedule = filter(product.children, { templateName: "Dagschema" });

  // Fast return when schedule isn't set
  if (!schedule || !schedule.length || !scheduleEntries || !scheduleEntries.length) {
    return <></>;
  }

  // Parse all locations
  let locations = [];
  if (schedule) {
    locations = schedule.map(day => {
      if (get(day, "content.general.location")) {
        return JSON.parse(day.content.general.location);
      }
      return undefined;
    });
  }

  return (
    <section className="product-schedule">
      <div className="product-schedule__title">
        <h2>Vaarschema</h2>
      </div>
      <div className="product-schedule__content">
        <div className="product-schedule__map">{locations && <GoogleMap locations={locations} />}</div>
        <div className="product-schedule__itinerary">
          {scheduleEntries && scheduleEntries.map((x, i) => <ScheduleLeg scheduleEntrie={x} index={i}></ScheduleLeg>)}
        </div>
      </div>
    </section>
  );
};

export default Schedule;
