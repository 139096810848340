import React from "react";
import ShipCard from "./ship-card";
import { getStars } from "../../utils/mappingUtils";

export default ({ product }) => {
  if (!product?.content?.general?.ship) {
    return <></>;
  }

  const ship = product.content.general.ship;
  let stars = getStars(ship?.content.general.stars);

  return (
    ship && (
      <section className="product-ship">
        <div className="product-ship__title">
          <h2>
            Het cruiseschip {ship.content.general.name}
            <sup className="product-ship__rating">
              {stars.map((star, i) => (
                <i key={i} className={`fa ${star.isHalf ? "fa-star-half" : "fa-star"}`} />
              ))}
            </sup>
          </h2>
        </div>
        <div className="product-ship__content">
          <ShipCard ship={ship} />
        </div>
      </section>
    )
  );
};
