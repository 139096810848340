import React, { useEffect, useState } from "react";
import { apiKey, host, catalogueId } from "../../settings";
import { find, uniq, uniqBy } from "lodash";
import { isValidEmail, isValidName, isValidPhone } from "../../utils/validationUtils";
import SubmitButton from "../submitButton";
import { environment } from "../../environments/environment";
import { format } from "../../utils/dateUtils";
import { useBodyClass } from "../../utils/bodyClassUtils";
import { v4 as uuid } from "uuid";

const Offer = ({
  isActive,
  cmsProduct,
  erpProductOptions,
  initialOption,
  onCloseClick,
  theme,
  websiteGeneral,
}) => {
  const dateFormat = "dd MMMM yyyy";
  const offerDateFormat = "MM/dd/yyyy";
  const initialSelection = {
    date: initialOption ? initialOption.fromDate : "",
    accommodation: initialOption ? initialOption.accommodationName : "",
    adults: "2",
    children: "0",
    salutation: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    message: "",
    checkPrivacyPolicy: false,
    travelAgency: "",
  };
  
  const productCode = erpProductOptions[0].code;
  // State

  const [isEnabled, setIsEnabled] = useState(true);
  const [selection, setSelection] = useState(initialSelection);
  const [selectedOptionPrice, setSelectedOptionPrice] = useState(null);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  // Actions

  const handleSubmit = e => {
    e.preventDefault();

    // Set all input fields to touched, so errors will become clear
    setTouched(
      Object.keys(selection).reduce((prev, cur) => {
        prev[cur] = true;
        return prev;
      }, {})
    );

    // Check if the form is valid
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      // Send offer request
      sendOfferRequest();
    }
  };

  const handleChange = e => {
    if (e.target.name === "date") {
      // Update default accommodation when data changes
      setSelection({
        ...selection,
        [e.target.name]: e.target.value,
        accommodation: getAccommodations(e.target.value)[0].code,
      });
      setSelectedOptionPrice(getAccommodations(e.target.value)[0].price);
    } else if (e.target.name === "accommodation") {
      setSelection({
        ...selection,
        [e.target.name]: e.target.value,
      });
      const accommodation = getAccommodations(selection.date).find(a => a.code === e.target.value);
      setSelectedOptionPrice(accommodation?.price);
    } else if (e.target.type === "checkbox") {
      setSelection({
        ...selection,
        [e.target.name]: e.target.checked,
      });
    } else {
      setSelection({
        ...selection,
        [e.target.name]: e.target.value,
      });
    }

    // Validation of selection
    validate({
      ...selection,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = e => {
    setTouched({
      ...touched,
      [e.target.name]: true,
    });
  };

  // Data

  const sendOfferRequest = () => {
    return (async () => {
      setIsLoading(true);
      setIsEnabled(false);

      // Create offer in Tide
      try {
        await createTideOffer();
      } catch (error) {
        console.error(error);
      }

      // Create mail offer
      try {
        await createMailOffer();

        setIsSent(true);
      } catch (error) {
        console.error(error);
      }

      //Confirmation mail to Thalassa
      try {
        await ConfirmationMailNewOffer();
      } catch (error) {
        setIsEnabled(true);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  const createTideOffer = async () => {
    const offerRequest = {
      officeId: environment.builds[0].officeId,
      agentId: environment.builds[0].agentId,
      productCode: productCode,
      startDate: format(new Date(selection.date), offerDateFormat),
      endDate: format(new Date(find(erpProductOptions, option => option.from === selection.date).to), offerDateFormat),
      address: {
        email: selection.email,
        mobilePhone: selection.phone,
      },
      rooms: parseRooms(),
      catalogueId: catalogueId,
    };

    const url = `${host}/api/web/booking/offer-entry`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": apiKey,
      },
      body: JSON.stringify(offerRequest),
    });
  };

  const createMailOffer = async () => {
    const emailToSend = {
      firstName: selection.firstname,
      lastName: selection.lastname,
      emailAddress: selection.email,
      phone: selection.phone,
      emailTemplate: websiteGeneral.emailTemplateOffer.tideId,
      ignoreBcc: true,
      message: `
            <ul>
            <li>Begroeting: ${selection.salutation}</li>
            <li>Achternaam: ${selection.lastname}</li>
            <li>Voornaam: ${selection.firstname}</li>
            <li>E-mailadres: ${selection.email}</li>
            <li>Telefoonnummer: ${selection.phone}</li>
            <li>Product: ${cmsProduct.content.general.title}</li>
            <li>vertrekdatum: ${format(new Date(selection.date), dateFormat)}</li>
            <li>Kajuit: ${selection.accommodation}</li>
            <li>Aantal volwassenen: ${selection.adults}</li>
            <li>Aantal kind(eren): ${selection.children}</li>
            <li>Opmerkingen: ${selection.message}</li>
            ${theme == "select-together" ? `<li>Ik wens te boeken via ${selection.travelAgency}</li>` : `<br>`}
           </ul>`,
    };

    const url = `${host}/api/web/mail`;

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": apiKey,
      },
      body: JSON.stringify(emailToSend),
    });
  };

  const ConfirmationMailNewOffer = async () => {
    const emailToSend = {
      firstName: selection.firstname,
      lastName: selection.lastname,
      emailAddress: "cruise@thalassa.be",
      phone: selection.phone,
      emailTemplate: websiteGeneral.emailTemplateOfferTravelAgent.tideId,
      ignoreBcc: true,
      message: `
            <p>Nieuwe offer aanvraag van ${selection.salutation} ${selection.firstname} ${selection.lastname}:<br>
            <ul>
            <li>Begroeting: ${selection.salutation}</li>
            <li>Achternaam: ${selection.lastname}</li>
            <li>Voornaam: ${selection.firstname}</li>
            <li>Telefoonnummer: ${selection.phone}</li>
            <li>E-mailadres: ${selection.email}</li>
            <li>Product: ${cmsProduct.content.general.title}</li>
            <li>vertrekdatum: ${format(new Date(selection.date), dateFormat)}</li>
            <li>Kajuit: ${selection.accommodation}</li>
            <li>Aantal volwassenen: ${selection.adults}</li>
            <li>Aantal kind(eren): ${selection.children}</li>
            <li>Opmerkingen: ${selection.message}</li>
            ${theme == "select-together" ? `<li>Ik wens te boeken via ${selection.travelAgency}</li>` : `<br>`}
           </ul>`,
    };

    const url = `${host}/api/web/mail`;

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Key": apiKey,
      },
      body: JSON.stringify(emailToSend),
    });
  };

  // Utils

  const getDepartureDates = () => {
    // Fast escape
    if (!erpProductOptions) {
      return [];
    }

    // Get fromDates, filter unique
    const fromDates = erpProductOptions.map(option => option.fromDate);
    return uniq(fromDates);
  };

  const getAccommodations = from => {
    if (!erpProductOptions || !from) {
      return [];
    }

    // Get options for selected date
    const optionsForDate = erpProductOptions.filter(option => option.fromDate === from);
    let accommodations = [];
    optionsForDate.forEach(option => {
      accommodations.push({
        code: option.accommodationCode,
        name: option.accommodationName,
        price: option.averagePricePerPerson
      })
    });

    return uniqBy(accommodations, "code");
  };

  const parseRooms = () => {
    const erpProduct = {};
    let accommodation = find(erpProduct.accommodations, {
      id: Number(selection.accommodation),
    });
    let adults = Number(selection.adults);
    let children = Number(selection.children);

    const roomsForAdults = accommodation.maxAdults > 0 ? Math.ceil(adults / accommodation.maxAdults) : 0;
    const roomsForChildren = accommodation.maxChildren > 0 ? Math.ceil(children / accommodation.maxChildren) : 0;
    const numberOfRooms = roomsForAdults > roomsForChildren ? roomsForAdults : roomsForChildren;

    let rooms = [];
    for (let i = numberOfRooms; i > 0; i--) {
      if (adults > i * accommodation.minAdults) {
        rooms.push({
          accommodationCode: accommodation.code,
          travelers: createAdultTravelers(accommodation.maxAdults),
        });
        adults = adults - accommodation.maxAdults;
      } else if (adults >= accommodation.minAdults) {
        rooms.push({
          accommodationCode: accommodation.code,
          travelers: createAdultTravelers(accommodation.minAdults),
        });
        adults = adults - accommodation.minAdults;
      } else {
        rooms.push({
          accommodationCode: accommodation.code,
          travelers: createAdultTravelers(adults),
        });
        adults = 0;
      }
    }

    for (let i = numberOfRooms; i > 0; i--) {
      if (children && children >= accommodation.maxChildren) {
        rooms[rooms.length - i].travelers = rooms[rooms.length - i].travelers.concat(
          createChildTravelers(accommodation.maxChildren)
        );
        children = children - accommodation.maxChildren;
      } else if (children) {
        rooms[rooms.length - i].travelers = rooms[rooms.length - i].travelers.concat(
          createChildTravelers(accommodation.children)
        );
        children = 0;
      }
    }

    return rooms;
  };

  const createAdultTravelers = adults => {
    let travelers = [];
    for (let i = 0; i < adults; i++) {
      travelers.push({
        id: -i,
        guid: uuid(),
        firstName: "Traveler",
        lastName: i,
        age: 30,
      });
    }
    return travelers;
  };

  const createChildTravelers = children => {
    let travelers = [];
    for (let i = 0; i < children; i++) {
      travelers.push({
        id: -i,
        guid: uuid(),
        firstName: "Traveler",
        lastName: i,
        age: 6,
      });
    }
    return travelers;
  };

  // Validation

  const validate = selectionToValidate => {
    const errors = {};
    if (selectionToValidate.date.length === 0 || !new Date(selectionToValidate.date)) {
      errors.date = {
        message: "Vertrekdatum",
      };
    }

    if (selectionToValidate.accommodation.length === 0) {
      errors.accommodation = {
        message: "Type hut",
      };
    }

    if (isNaN(selectionToValidate.adults) || selectionToValidate.adults < 1) {
      errors.adults = {
        message: "Aantal volwassenen",
      };
    }

    if (isNaN(selectionToValidate.children) || selectionToValidate.children < 0) {
      errors.children = {
        message: "Aantal kinderen",
      };
    }

    if (selectionToValidate.salutation.length === 0) {
      errors.salutation = {
        message: "Aanspreektitel",
      };
    }

    if (!isValidName(selectionToValidate.firstname)) {
      errors.firstname = {
        message: "Voornaam",
      };
    }

    if (!isValidName(selectionToValidate.lastname)) {
      errors.lastname = {
        message: "Achternaam",
      };
    }

    if (selectionToValidate.phone.length === 0) {
      errors.phone = "Telefoonnummer";
    }

    if (!isValidPhone(selectionToValidate.phone)) {
      errors.phone = {
        message: "Telefoonnummer",
      };
    }

    if (!isValidEmail(selectionToValidate.email)) {
      errors.email = {
        message: "Email",
      };
    }

    if (!selectionToValidate.checkPrivacyPolicy) {
      errors.checkPrivacyPolicy = {
        message: "checkbox privacy policy *",
      };
    }

    setErrors(errors);
  };

  // Lifecycle

  useBodyClass(isActive, "has-overlay");

  useEffect(() => {
    validate(selection);
  }, [selection]);

  useEffect(() => {
    if (initialOption) {
      setSelectedOptionPrice(initialOption.averagePricePerPerson);
    }
  }, [initialOption]);

  return (
    <>
      {Boolean(
        cmsProduct.content && cmsProduct.content.general && cmsProduct.content.information && cmsProduct.content.images
      ) && (
        <section className={isActive ? "flyin flyin--right flyin--active" : "flyin flyin--right"}>
          <section className="flyin__backdrop" onClick={onCloseClick}></section>
          <section className="flyin__frame">
            <section className="flyin__body">
              <section className="product-offer">
                <header className="product-offer__heading">
                  <figure>
                    <img src={cmsProduct.content.images.image1?.url} alt="Header" />
                    <figcaption className="product-offer__caption">
                      <div className="product-offer__information">
                        <p>Offerte aanvragen</p>
                        <h3>{cmsProduct.content.general.title}</h3>
                      </div>
                      <div className="product-offer__description">
                        <h4>{cmsProduct.content.information.subtitle}</h4>

                        <div className="product-offer__price">
                          <div className="product-offer__price-from">Vanaf p.p. </div>
                          <span className="product-offer__price-amount">€ {selectedOptionPrice ?? "n.b."}</span>
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                  <button className="button button--close" onClick={onCloseClick} />
                </header>

                <div className="form-wrapper">
                  <form className="product-offer__form form">
                    <div className="form__row">
                      <div className="form__col">
                        <h3>Gegevens reis</h3>
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__col form__col--sm-50">
                        <label
                          htmlFor="date"
                          className={`form__label ${touched.date && errors.date ? "form__label--error" : ""}`}
                        >
                          Vertrekdatum
                        </label>
                        <select
                          id="date"
                          name="date"
                          className={`form__input flyin__select${
                            touched.date && errors.date ? "form__input-error" : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={selection.date}
                        >
                          {getDepartureDates().map((departureDate, index) => (
                            <option key={index} value={departureDate}>
                              {format(new Date(departureDate), dateFormat)}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form__col form__col--sm-50">
                        <label
                          htmlFor="accommodation"
                          className={`form__label ${
                            touched.accommodation && errors.accommodation ? "form__label--error" : ""
                          }`}
                        >
                          Type hut
                        </label>
                        <select
                          id="accommodation"
                          name="accommodation"
                          className={`form__input flyin__select ${
                            touched.accommodation && errors.accommodation ? "form__input-error" : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={selection.accommodation}
                        >
                          {getAccommodations(selection.date).map((accommodation, index) => (
                            <option key={index} value={accommodation.code}>
                              {accommodation.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__col form__col--sm-50">
                        <label
                          htmlFor="adults"
                          className={`form__label ${touched.adults && errors.adults ? "form__label--error" : ""}`}
                        >
                          Aantal volwassenen
                        </label>
                        <input
                          id="adults"
                          type="number"
                          name="adults"
                          className={`form__input ${touched.adults && errors.adults ? "form__input--error" : ""}`}
                          value={selection.adults}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          min="1"
                        />
                      </div>
                      <div className="form__col form__col--sm-50">
                        <label
                          htmlFor="children"
                          className={`form__label ${touched.children && errors.children ? "form__label--error" : ""}`}
                        >
                          Aantal kinderen
                        </label>
                        <input
                          id="children"
                          type="number"
                          name="children"
                          className={`form__input ${touched.children && errors.children ? "form__input--error" : ""}`}
                          value={selection.children}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          min="0"
                        />
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__col">
                        <h3 className="form__col__title">Uw gegevens</h3>
                      </div>
                    </div>
                    <div className="form__row form__row--salutation">
                      <div className="form__col">
                        <label
                          className={`form__label ${
                            touched.salutation && errors.salutation ? "form__label--error" : ""
                          }`}
                        >
                          Aanspreektitel *
                        </label>
                        <div className="radio-buttons">
                          <div className="radio-button">
                            <input
                              id="sir"
                              type="radio"
                              name="salutation"
                              value="dhr"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label htmlFor="sir">Dhr</label>
                          </div>
                          <div className="radio-button">
                            <input
                              id="mrs"
                              type="radio"
                              name="salutation"
                              value="mw"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label htmlFor="mrs">Mw</label>
                          </div>
                          <div className="radio-button">
                            <input
                              id="different"
                              type="radio"
                              name="salutation"
                              value="anders"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <label htmlFor="different">Anders</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__row">
                      <div className="form__col form__col--sm-50">
                        <label
                          htmlFor="firstname"
                          className={`form__label ${touched.firstname && errors.firstname ? "form__label--error" : ""}`}
                        >
                          Voornaam *
                        </label>
                        <input
                          id="firstname"
                          name="firstname"
                          className={`form__input ${touched.firstname && errors.firstname ? "form__input--error" : ""}`}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="form__col form__col--sm-50">
                        <label
                          htmlFor="lastname"
                          className={`form__label ${touched.lastname && errors.lastname ? "form__label--error" : ""}`}
                        >
                          Achternaam *
                        </label>
                        <input
                          id="lastname"
                          name="lastname"
                          className={`form__input ${touched.lastname && errors.lastname ? "form__input--error" : ""}`}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__col form__col--sm-50">
                        <label
                          htmlFor="phone"
                          className={`form__label ${touched.phone && errors.phone ? "form__label--error" : ""}`}
                        >
                          Telefoonnummer *
                        </label>
                        <input
                          id="phone"
                          name="phone"
                          className={`form__input ${touched.phone && errors.phone ? "form__input--error" : ""}`}
                          type="tel"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="form__col form__col--sm-50">
                        <label
                          htmlFor="email"
                          className={`form__label ${touched.email && errors.email ? "form__label--error" : ""}`}
                        >
                          E-mailadres *
                        </label>
                        <input
                          id="email"
                          name="email"
                          className={`form__input ${touched.email && errors.email ? "form__input--error" : ""}`}
                          type="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__col">
                        <label htmlFor="message" className="form__label">
                          Opmerkingen
                        </label>
                        <textarea
                          id="message"
                          type="text"
                          name="message"
                          value={selection.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    {theme === "select-together" && (
                      <div className="form__row">
                        <div className="form__col">
                          <label htmlFor="travelAgency" className="form__label">
                            Ik wens te boeken via volgend reisbureau:
                          </label>
                          <input
                            id="travelAgency"
                            name="travelAgency"
                            className="form__input"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    )}
                    <div className="form__row">
                      <div className="form__col">
                        <label className="checkbox">
                          <input
                            id="checkPrivacyPolicy"
                            className={`checkbox__input ${
                              touched.checkPrivacyPolicy && errors.checkPrivacyPolicy ? "checkbox__input--error" : ""
                            }`}
                            type="checkbox"
                            name="checkPrivacyPolicy"
                            value={selection.checkPrivacyPolicy}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className="checkbox__label">
                            Ik ga akkoord met de{" "}
                            <a href={`/${websiteGeneral.privacyPolicyPath}` ?? "/"} target="_blank">
                              privacy policy *
                            </a>
                            .
                          </span>
                        </label>
                      </div>
                    </div>
                    {Boolean(Object.keys(touched).filter(key => Boolean(errors[key])).length) && (
                      <div className="form__row form__row--alter">
                        <div className="form__col">
                          <div className="error">Volgende velden zijn niet correct ingevuld. Kijk deze even na:</div>
                        </div>
                        <div className="form__col form__col--errors">
                          {Object.keys(touched)
                            .filter(key => Boolean(errors[key]))
                            .map((item, index) => {
                              return (
                                <label className="errorlabel" key={index}>
                                  {errors[item].message}
                                </label>
                              );
                            })}
                        </div>
                      </div>
                    )}
                    {Boolean(isSent) && (
                      <div className="form__row">
                        <div className="form__col">
                          <label className="successlabel">Uw aanvraag is verstuurd, bedankt!</label>
                        </div>
                      </div>
                    )}
                    <div className="form__row">
                      <div className="form__col">
                        <SubmitButton
                          text="Vraag offerte aan"
                          isLoading={isLoading}
                          isEnabled={isEnabled}
                          onClick={handleSubmit}
                          isSecondary={false}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default Offer;
