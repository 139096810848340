import React from "react";
import { filter } from "lodash";
import { addWidthParameter } from "../../utils/addWidthParameter";

export default ({ theme, product }) => {
  let cabins = [];
  const allcabins = filter(product.children, { templateName: "Cabins" })[0];
  // Fast return when there's no cabins folder
  if (!allcabins) {
    return <></>;
  }
  allcabins.children.forEach(cabin => {
    cabins.push(cabin);
  });

  // Fast return when there's no cabins
  if (cabins.length <= 0) {
    return <></>;
  }

  return (
    <section className="product-cabins">
      <div className="product-cabins__title">
        <h2>{allcabins.content.general.title}</h2>
      </div>

      {cabins &&
        cabins.map((cabin, index) => (
          <div className="product-cabins__item" key={cabin.itemId}>
            <div className="product-cabins__media">
              <img src={addWidthParameter(cabin.content.general.image?.url, 1168)} alt={cabin.content.general.title} />
            </div>
            <div className="product-cabins__content">
              <div className="product-cabins__header">
                <div className="product-cabins__itemtitle">
                  <h3>{cabin.content.general.title}</h3>
                  <div className="product-cabins__surface">
                    <img src={`/${theme}/svg/surface.svg`} alt="surface" />
                    <span>
                      {cabin.content.general.surface} m<sup>2</sup>
                    </span>
                  </div>
                </div>

                <div className="product-detail__price">
                  <label className="product-detail__price-label">Vanaf</label>
                  <div className="product-detail__price-line">
                    <span className="product-detail__price-valuta">€</span>
                    <span className="product-detail__price-cost">{cabin.content.general.price}</span>
                    <span className="product-detail__price-quantity">p.p.</span>
                  </div>
                </div>
              </div>
              <div
                className="product-cabins__body"
                dangerouslySetInnerHTML={{ __html: cabin.content.general.description }}
              ></div>
            </div>
          </div>
        ))}
    </section>
  );
};
