import React from "react";
import { Link } from "gatsby";
import HighlightedTextblock from "../highlighted-textblock";

const ReservationInformation = ({ data, options, onOfferClick, theme }) => {
  if (!data) {
    return <></>;
  }

  return (
    <HighlightedTextblock
      image={data.reservationImage?.url}      
    >
      <div className="highlighted-textblock__group">
        <h2>{data.reservationTitle}</h2>
      </div>
      <div dangerouslySetInnerHTML={{ __html: data.reservationText }}></div>
      {Boolean(options?.length) && (
        <div className="highlighted-textblock__group highlighted-textblock__group--actions">
          <div className="product-reservation-information__cta">
            <button onClick={onOfferClick}>Offerte aanvragen</button>
          </div>
        </div>
      )}
    </HighlightedTextblock>
  );
};

export default ReservationInformation;
