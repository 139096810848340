import { addYears, endOfYear, fromUnixTime } from "date-fns";
import { apiKey, catalogueId, host, websiteName } from "../settings";

import { graphql } from "gatsby";
import { first, isEmpty } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../components/loadingSpinner";
import Cabins from "../components/product/cabins";
import Description from "../components/product/description";
import Detail from "../components/product/detail";
import ExtraInformation from "../components/product/extraInformation";
import Hotels from "../components/product/hotels";
import Offer from "../components/product/offer";
import PriceOptions from "../components/product/priceOptions";
import ReservationInformation from "../components/product/reservationInformation";
import Schedule from "../components/product/schedule";
import Ship from "../components/product/ship";
import SeoHeaders from "../components/seo/seo-headers";
import GlobalContext from "../contexts/global-provider";
import { environment } from "../environments/environment";
import Layout from "../templates/layout";
import { format, getDuration } from "../utils/dateUtils";

require("isomorphic-fetch");

const Product = ({ location, pageContext, data }) => {
  const dateFormat = "yyyy/MM/dd";
  const { product, hotels, canonical, cruiseTypes } = pageContext;
  const content = product.content;
  const seo = content.seo;
  const pageTitle = seo && seo.title ? seo.title : content.general.title;

  const { setNavigation, setPrivacy, theme } = useContext(GlobalContext);
  setNavigation(data.website.content.navigation);
  setPrivacy(data.website.content.general.privacyPolicyPath);

  const [isLoading, setIsLoading] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hotelsPrePostNights, setHotelsPrePostNights] = useState([]);
  const [scheduleEntries, setScheduleEntries] = useState(undefined);
  const [erpProductOptions, setErpProductOptions] = useState(undefined);
  const [pathName, setPathName] = useState(undefined);
  const priceOptionsRef = useRef(null);

  var queryParameters = new URLSearchParams();
  if (typeof window !== "undefined") {
    queryParameters = new URLSearchParams(window.location.search);
  }
  const selectedDuration = queryParameters?.get("duration") ? Number(queryParameters?.get("duration")) : null;
  const selectedFrom = queryParameters?.get("from") ? fromUnixTime(queryParameters.get("from")) : new Date();

  const fetchSchedule = async () => {
    const initialScheduleEntries = product.children.filter(x => x.templateName === "Dagschema");
    // get ERP Product from ID
    const ERPProduct = await fetchHotels(product.content.general.product.key);
    // get all hotels in the package
    const ERPHotels = ERPProduct.packageProducts.filter(p => p.product.productType === 3);
    // For each day to day item
    initialScheduleEntries.forEach(scheduleEntry => {
      // <--- LINK HOTELS TO DAYCOMPONENT --->

      // gather all ID's from hotels in the package where name == optiongroup, for example: "Dag 1-5"
      const hotelIDs = [];
      ERPHotels.forEach(h => {
        if (h.optionGroup === scheduleEntry.name) {
          hotelIDs.push(h.product.id);
        }
      });

      // Filter cmsHotels so you only have those with an ID that's part of the package and day
      if (hotels && hotels[0] && hotels[0].children && hotels[0].children.length) {
        const filteredHotels = hotels[0].children.filter(h => hotelIDs.includes(h.content.general.product.key));
        scheduleEntry.hotels = filteredHotels;
        setHotelsPrePostNights(prevHotels => [...prevHotels, ...filteredHotels]);
      }
    });
    setScheduleEntries(initialScheduleEntries);
  };

  const fetchHotels = async id => {
    const response = await fetch(`${host}/api/web/products/dynamicinfo/${id}`, {
      method: "GET",
      headers: {
        "Api-Key": apiKey,
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  };

  // Data
  const fetchErpProductOptions = async () => {
    const productId = product.content.general.product.key;

    const searchRequest = {
      officeId: environment.builds[0].officeId,
      payload: {
        catalogueIds: [catalogueId],
        rooms: [
          {
            index: 0,
            pax: [
              {
                age: 25,
              },
              {
                age: 25,
              },
            ],
          },
        ],
        serviceType: 11,
        searchType: 1,
        fromDate: format(new Date(), dateFormat),
        toDate: format(endOfYear(addYears(new Date(), 2)), dateFormat),
        productIds: [productId],
        includeAllAllotmentAccommodations: true,
      },
    };

    const url = `${host}/api/web/booking/v2/search`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Api-Key": apiKey,
          "Content-Type": "application/json",
          Language: "nl-BE",
        },
        body: JSON.stringify(searchRequest),
      });

      if (response.ok) {
        return await response.json();
      }
      return undefined;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (typeof window !== "undefined") {
          setPathName(window.location.pathname);
        }
        await fetchSchedule();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (queryParameters) {
          setIsLoading(true);
          const availabilities = await fetchErpProductOptions();
          if (availabilities && availabilities.length) {
            if (selectedDuration) {
              const validAvailabilities = availabilities.filter(x => {
                const duration = getDuration(x.fromDate, x.toDate);
                return selectedDuration === duration;
              });

              if (validAvailabilities && !isEmpty(validAvailabilities)) {
                setErpProductOptions(validAvailabilities);
                let option = validAvailabilities.find(
                  x => format(new Date(x.fromDate), dateFormat) === format(selectedFrom, dateFormat)
                );
                if (!option) {
                  option = first(validAvailabilities);
                }
                setSelectedOption(option);
              }
            }
            else {
              setErpProductOptions(availabilities);
              setSelectedOption(first(availabilities));
            }
          }

          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    })();
  }, []);

  const handleOfferClick = () => {
    setShowOffer(true);
  };
  const handleOfferCloseClick = () => {
    setShowOffer(false);
  };
  const handleOnDepartureDateClick = () => priceOptionsRef.current?.scrollIntoView({ behavior: "smooth" });
  return (
    <Layout isTiny={true} showQsm={false} isTinted={true} cruiseTypes={cruiseTypes}>
      <SeoHeaders
        seo={seo}
        pageTitle={pageTitle}
        pagePath={location.pathname}
        siteName={websiteName[theme]}
        canonical={canonical}
        pathName={pathName}
      />
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {isLoading && <LoadingSpinner text="Uw cruise wordt geladen" />}
      {!isLoading && (
        <>
          <Detail
            theme={theme}
            cmsProduct={product}
            erpProductOptions={erpProductOptions}
            onOfferClick={() => handleOfferClick()}
            onDepartureDateClick={() => handleOnDepartureDateClick()}
            selectedOption={selectedOption}
            bookingDisclaimer={data.website.content.general.bookingDisclaimer}
            shippingCompany={data.shippingCompany}
            isLoading={isLoading}
          />
          <Description product={product} />
          <Schedule theme={theme} product={product} scheduleEntries={scheduleEntries} />
          <Ship product={product} />
          <PriceOptions innerRef={priceOptionsRef} cmsProduct={product} erpProductOptions={erpProductOptions} />
          <Cabins theme={theme} product={product} />
          <Hotels hotels={hotelsPrePostNights} />
          <ExtraInformation product={product} />
          <ReservationInformation
            data={data.website.content.reservation}
            options={erpProductOptions}
            onOfferClick={() => handleOfferClick()}
            theme={theme}
          />
          {Boolean(erpProductOptions) && (
            <Offer
              isActive={showOffer}
              cmsProduct={product}
              erpProductOptions={erpProductOptions}
              initialOption={selectedOption}
              onCloseClick={() => handleOfferCloseClick()}
              theme={theme}
              websiteGeneral={data.website.content.general}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default Product;

export const query = graphql`
  query ProductPageQuery($websiteId: String, $shippingCompanyId: Int) {
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      id
      name
      __typename
      content {
        general {
          bookingDisclaimer
          emailTemplateOffer {
            tideId
          }
          emailTemplateOfferTravelAgent {
            tideId
          }
          privacyPolicyPath
        }
        navigation {
          contactNavigation {
            ...NavigationItemFragment
          }
          footerNavigation {
            ...NavigationItemFragment
          }
          headerNavigation {
            ...NavigationItemFragment
          }
        }
        reservation {
          reservationImage {
            altText
            title
            url
          }
          reservationText
          reservationTitle
        }
      }
    }
    shippingCompany: tideItemForRederij(itemId: { eq: $shippingCompanyId }) {
      __typename
      content {
        general {
          title
          logo {
            altText
            title
            url
          }
          detailPath
        }
      }
    }
  }
`;
