import React from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";
import { getStars } from "../../utils/mappingUtils";

export default ({ hotels }) => {
  // Fast return when there's no cabins
  if (hotels.length <= 0) {
    return <></>;
  }

  return (
    <section className="product-cabins" id="hotelInfo">
      <div className="product-cabins__title">
        <h2>Hotelinformatie</h2>
      </div>

      {hotels &&
        hotels.map((h, index) => (
          <div className="product-cabins__item" key={h.itemId}>
            <div className="product-cabins__media">
              <img src={addWidthParameter(h.content.general.image?.url, 1168)} alt={h.content.general.title} />
            </div>
            <div className="product-cabins__content">
              <div className="product-cabins__header">
                <div className="product-cabins__itemtitle">
                  <h3>{h.content.general.title}</h3>
                  <div className="product-detail__subtitle">
                    <sup className="product-detail__rating">
                      {getStars(h.content.general.stars).map((star, i) => (
                        <i key={i} className={`fa ${star.isHalf ? "fa-star-half" : "fa-star"}`} />
                      ))}
                    </sup>
                  </div>
                </div>
              </div>
              <div
                className="product-cabins__body"
                dangerouslySetInnerHTML={{ __html: h.content.general.description }}
              ></div>
            </div>
          </div>
        ))}
    </section>
  );
};
