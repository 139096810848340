import React from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { googleApiKey } from "../../settings";
import { max, min } from "lodash";

const GoogleMap = ({ locations }) => {
  const mapDayScheme = locations
    .map((location, index) => {
      return { location: location, index: index + 1 };
    })
    .filter(day => day.location && day.location.latitude && day.location.longitude);

  const { center, zoom } = fitBounds(
    {
      ne: {
        lat: max(mapDayScheme.map(day => day.location)?.map(l => l.latitude)) ?? 0,
        lng: max(mapDayScheme.map(day => day.location)?.map(l => l.longitude)) ?? 0,
      },
      sw: {
        lat: min(mapDayScheme.map(day => day.location)?.map(l => l.latitude)) ?? 0,
        lng: min(mapDayScheme.map(day => day.location)?.map(l => l.longitude)) ?? 0,
      },
    },
    { width: 500, height: 500 }
  );

  const createMapOptions = () => {
    return {
      panControl: true,
      gestureHandling: "cooperative",
      draggableCursor: "default",
      streetViewControl: false,
      mapTypeControl: true,
      fullscreenControl: true,
      clickableIcons: false,
    };
  };

  const handleApiLoaded = (map, maps) => {
    mapDayScheme.map(marker => {
      new maps.Marker({
        //Create a new marker and use the map's center as the location.
        position: { lat: marker.location.latitude, lng: marker.location.longitude },
        map: map,
        title: "Marker!",
        label: marker.index.toString(),
      });
    });
  };

  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleApiKey }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </div>
  );

  // const mapRef = useRef();

  // const onScriptLoad = () => {
  //   // Filter out locations without coordinates, keep the day index, construct a day scheme

  //   if (mapRef.current && mapDayScheme) {
  //     const center = calcCenter(mapDayScheme);

  //     // If there is no valid center then something is wrong the day definitions and the map should not be shown
  //     if (center && center.lat && center.lng) {
  //       const startData = {
  //         zoom: 6,
  //         center: center,
  //         disableDefaultUI: true,
  //         gestureHandling: "cooperative",
  //         zoomControl: true,
  //         scaleControl: true,
  //       };

  //       const map = new window.google.maps.Map(mapRef.current, startData);
  //       mapDayScheme.forEach(day => {
  //         if (day && day.location && day.index) {
  //           const marker = new window.google.maps.Marker({
  //             position: { lat: day.location.latitude, lng: day.location.longitude },
  //             map: map,
  //             title: day.location.searchValue,
  //             label: { text: `${day.index}`, color: "white" },
  //           });
  //         }
  //       });
  //     }
  //   }
  // };

  // const getLat = data => {
  //   return data.map(d => d && d.latitude);
  // };
  // const getLng = data => {
  //   return data.map(d => d && d.longitude);
  // };

  // const calcCenter = mapDayScheme => {
  //   const mapDaySchemeLocations = mapDayScheme.map(day => day.location);

  //   const latMin = Math.min(...getLat(mapDaySchemeLocations));
  //   const latMax = Math.max(...getLat(mapDaySchemeLocations));
  //   const lngMin = Math.min(...getLng(mapDaySchemeLocations));
  //   const lngMax = Math.max(...getLng(mapDaySchemeLocations));

  //   const latCenter = (latMax + latMin) / 2.0;
  //   const lngCenter = (lngMax + lngMin) / 2.0;

  //   return { lat: latCenter, lng: lngCenter };
  // };

  // useEffect(() => {
  //   if (!window.google) {
  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.src = `https://maps.google.com/maps/api/js?key=AIzaSyD9FhsgpnYloyOo0jLPfHmMtlu2W8ZY114`; // add '?key=AIzaSyD9FhsgpnYloyOo0jLPfHmMtlu2W8ZY114'
  //     document.body.appendChild(script);
  //     script.addEventListener("load", e => {
  //       onScriptLoad();
  //     });
  //   } else {
  //     onScriptLoad();
  //   }
  // }, []);

  // return <div className="map" ref={this.mapRef} />;
};

export default GoogleMap;
