import React from "react";

export default ({ product }) => {
  const extraInfo = product.content.additionalInformation;

  return (
    <section className="product-extra-info">
      <div className="product-extra-info__title">
        <h2>Aanvullende informatie</h2>
      </div>
      <div className="product-extra-info__content">
        <div>
          {extraInfo.childDiscount && (
            <>
              <h4>Kinderkorting</h4>
              <div dangerouslySetInnerHTML={{ __html: extraInfo.childDiscount }} />
            </>
          )}
          {extraInfo.travelFormalities && (
            <>
              <h4>Reisformaliteiten</h4>
              <div dangerouslySetInnerHTML={{ __html: extraInfo.travelFormalities }} />
            </>
          )}
          {extraInfo.transfers && (
            <>
              <h4>Transfers</h4>
              <div dangerouslySetInnerHTML={{ __html: extraInfo.transfers }} />
            </>
          )}
          {extraInfo.flights && (
            <>
              <h4>Vluchten</h4>
              <div dangerouslySetInnerHTML={{ __html: extraInfo.flights }} />
            </>
          )}
        </div>
        <div>
          {extraInfo.drinksOnBoard && (
            <>
              <h4>Dranken aan boord</h4>
              <div dangerouslySetInnerHTML={{ __html: extraInfo.drinksOnBoard }} />
            </>
          )}
          {extraInfo.notIncluded && (
            <>
              <h4>Niet inbegrepen</h4>
              <div dangerouslySetInnerHTML={{ __html: extraInfo.notIncluded }} />
            </>
          )}
          {extraInfo.conditions && (
            <>
              <h4>Voorwaarden</h4>
              <div dangerouslySetInnerHTML={{ __html: extraInfo.conditions }} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};
