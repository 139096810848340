import React from "react";

export default ({ product }) => (
  <section className="product-description">
    <div className="product-description__title">
      <h2>{product.content.information.introductionTitle}</h2>
    </div>
    <div
      className="product-description__content"
      dangerouslySetInnerHTML={{ __html: product.content.information.introductionText }}
    />
  </section>
);
